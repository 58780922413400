import '../i18n';

import { PageLoading } from '@amzn/sitc-frontend/components';
import { AuthSessionProvider, MicroAppConfigProvider } from '@amzn/sitc-frontend/contexts';
import { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import { backlotLightTheme } from '@amzn/sitc-frontend-theme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import i18n from 'i18next';
import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';

import { appDomainTemplate, MicroAppConfigContext, MicroAppConfigResultType } from '../utils/app';
import { FeatureConfiguration } from './FeatureConfiguration';

const Index = React.lazy(() => import('./Index'));
const View1 = React.lazy(() => import('./View1'));
const View2 = React.lazy(() => import('./View2'));

const LawlauFirstStudiosMicroApp: React.FC<MicroAppInitializationProps> = (props) => {
  const { authSession, basePath, stage, appId, featureOverrides, microAppName } = props;

  return (
    <ThemeProvider theme={backlotLightTheme}>
      <CssBaseline />
      <MicroAppConfigProvider<MicroAppConfigResultType>
        stage={stage}
        appName={microAppName}
        appId={appId}
        appDomainTemplate={appDomainTemplate}
        appLoader={<PageLoading />}
        context={MicroAppConfigContext}>
        <FeatureConfiguration featureOverrides={featureOverrides}>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter basename={basePath}>
              <AuthSessionProvider authSession={authSession}>
                <div>
                  <Tabs value={false}>
                    <Tab label="Home" value="/" to="/" component={Link} />
                    <Tab label="View 1" value="/view1" to="/view1" component={Link} />
                    <Tab label="View 2" value="/view2" to="/view2" component={Link} />
                  </Tabs>
                  <Suspense fallback="loading...">
                    <Routes>
                      <Route path="/" element={<Index {...props} />} />
                      <Route path="/view1" element={<View1 />} />
                      <Route path="/view2" element={<View2 />} />
                    </Routes>
                  </Suspense>
                </div>
              </AuthSessionProvider>
            </BrowserRouter>
          </I18nextProvider>
        </FeatureConfiguration>
      </MicroAppConfigProvider>
    </ThemeProvider>
  );
};

export default LawlauFirstStudiosMicroApp;
